import { ReactNode } from 'react';
import styled from 'react-emotion';
import { FlexBoxProps } from '@traveloka/soya-components';
import TripBox from '@ui-components/shared/TripBox/TripBox';

export interface TripFooterProps extends FlexBoxProps {
  children?: ReactNode;
}

const TripFooterContainer = styled(TripBox)`
  border: 0px solid transparent;
  text-align: center;
  background-color: transparent;
  font-size: 12px;
  letter-spacing: 0.5px;
  min-height: 50px;
`;

const TripFooter = (props: TripFooterProps) => {
  const { children, ...rest } = props;
  return <TripFooterContainer {...rest}>{children}</TripFooterContainer>;
};

export default TripFooter;
