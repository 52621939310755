import React, { Fragment } from 'react';
import styled from 'react-emotion';
import Link from 'next/link';

import { FlexBox, Block, Button } from '@traveloka/soya-components';
import { OIDCAuthorizeView } from '@shared/utils/soya-auth';

import TripCard from '@ui-components/shared/TripCard/TripCard';
import Truncator from '@ui-components/shared/Truncator/Truncator';
import TripFooter from '@ui-components/shared/TripLayout/TripFooter';

import {
  AUTH_SELECT_AND_SWITCH_PO,
  AUTH_READ_PAX_MANIFEST,
  AUTH_READ_PROVIDER,
  ROLE_BOOKING_MANIFEST_READ_ONLY,
  ROLE_SKU_READ_ONLY,
  ROLE_ALLOTMENT_MONITORING_READ_ONLY,
  ROLE_PRICING_ENGINE_READ_ONLY,
  ROLE_PRICING_SIMULATOR_READ_ONLY,
} from '@shared/constants/authPermission';

const TripCardContainer = styled('div')`
  & + & {
    margin-left: 32px;
  }
`;

const FullHeightLayout = styled('div')`
  width: 100%;
  min-height: calc(100vh - 132px);
`;

const TripFooterLinkContainer = styled('div')`
  & + & {
    margin-left: 32px;
  }
`;

class ProviderHomePage extends React.Component {
  verifyProviderPermission = (userPermissions: string[]) => {
    return (
      userPermissions.indexOf(AUTH_READ_PROVIDER) > -1 ||
      userPermissions.indexOf(AUTH_SELECT_AND_SWITCH_PO) > -1
    );
  };

  render() {
    return (
      <Fragment>
        <FullHeightLayout>
          <FlexBox padding="md" direction="row">
            <OIDCAuthorizeView permissions={AUTH_READ_PAX_MANIFEST}>
              <TripCardContainer>
                <TripCard>
                  <Block marginBottom="sm">
                    <Truncator fontSize={20} fontWeight={700} lineToShow={2}>
                      {'Jadwal Keberangkatan'}
                    </Truncator>
                  </Block>

                  <Block marginBottom="sm">
                    <Truncator lineToShow={3}>
                      {'Lihat jadwal keberangkatan yang Anda miliki'}
                    </Truncator>
                  </Block>

                  <Link href={'/manifest/passenger'}>
                    <Button id="button_pax_manifest" variant="green">
                      {'Lihat Jadwal Keberangkatan'}
                    </Button>
                  </Link>
                </TripCard>
              </TripCardContainer>
            </OIDCAuthorizeView>

            <OIDCAuthorizeView permissions={ROLE_SKU_READ_ONLY}>
              <TripCardContainer>
                <TripCard>
                  <Block marginBottom="sm">
                    <Truncator fontSize={20} fontWeight={700} lineToShow={2}>
                      {'Daftar Inventori'}
                    </Truncator>
                  </Block>

                  <Block marginBottom="sm">
                    <Truncator lineToShow={3}>
                      {'Lihat daftar inventori trayek yang Anda miliki'}
                    </Truncator>
                  </Block>

                  <Link href={'/sku'}>
                    <Button id="button_sku_view" variant="green">
                      {'Lihat Daftar Inventori'}
                    </Button>
                  </Link>
                </TripCard>
              </TripCardContainer>
            </OIDCAuthorizeView>

            <OIDCAuthorizeView permissions={ROLE_BOOKING_MANIFEST_READ_ONLY}>
              <TripCardContainer>
                <TripCard>
                  <Block marginBottom="sm">
                    <Truncator fontSize={20} fontWeight={700} lineToShow={2}>
                      {'Laporan Penjualan'}
                    </Truncator>
                  </Block>

                  <Block marginBottom="sm">
                    <Truncator lineToShow={3}>
                      {'Lihat dan unduh laporan penjualan yang Anda miliki'}
                    </Truncator>
                  </Block>

                  <Link href={'/manifest/booking'}>
                    <Button id="button_manifest_passenger" variant="green">
                      {'Lihat Laporan Perjalanan'}
                    </Button>
                  </Link>
                </TripCard>
              </TripCardContainer>
            </OIDCAuthorizeView>

            <OIDCAuthorizeView
              permissions={ROLE_ALLOTMENT_MONITORING_READ_ONLY}
            >
              <TripCardContainer>
                <TripCard>
                  <Block marginBottom="sm">
                    <Truncator fontSize={20} fontWeight={700} lineToShow={2}>
                      {'Monitor Alokasi'}
                    </Truncator>
                  </Block>

                  <Block marginBottom="sm">
                    <Truncator lineToShow={3}>
                      {
                        'Lihat dan monitor situasi alokasi penjatahan kursi untuk semua sku'
                      }
                    </Truncator>
                  </Block>

                  <Link href="/allotment/monitoring">
                    <Button id="button_allotment_monitoring" variant="green">
                      {'Lihat Monitor Alokasi'}
                    </Button>
                  </Link>
                </TripCard>
              </TripCardContainer>
            </OIDCAuthorizeView>
          </FlexBox>

          <FlexBox
            direction={'row'}
            paddingLeft={'md'}
            paddingRight={'md'}
            paddingBottom={'md'}
          >
            <OIDCAuthorizeView permissions={ROLE_PRICING_ENGINE_READ_ONLY}>
              <TripCardContainer>
                <TripCard>
                  <Block marginBottom="sm">
                    <Truncator fontSize={20} fontWeight={700} lineToShow={2}>
                      {'Paket Harga'}
                    </Truncator>
                  </Block>

                  <Block marginBottom="sm">
                    <Truncator lineToShow={3}>
                      {'Tambah dan atur paket harga untuk periode tertentu'}
                    </Truncator>
                  </Block>

                  <Link href="/pricing-engine">
                    <Button id="button_pricing_engine" variant="green">
                      {'Lihat Paket Harga'}
                    </Button>
                  </Link>
                </TripCard>
              </TripCardContainer>
            </OIDCAuthorizeView>

            <OIDCAuthorizeView permissions={ROLE_PRICING_SIMULATOR_READ_ONLY}>
              <TripCardContainer>
                <TripCard>
                  <Block marginBottom="sm">
                    <Truncator fontSize={20} fontWeight={700} lineToShow={2}>
                      {'Simulasi Harga'}
                    </Truncator>
                  </Block>

                  <Block marginBottom="sm">
                    <Truncator lineToShow={3}>
                      {
                        'Cek harga dan inventori yang sudah anda set dengan mudah'
                      }
                    </Truncator>
                  </Block>

                  <Link href="/pricing-simulator">
                    <Button id="button_pricing_simulator" variant="green">
                      {'Lihat Simulasi Harga'}
                    </Button>
                  </Link>
                </TripCard>
              </TripCardContainer>
            </OIDCAuthorizeView>
          </FlexBox>
        </FullHeightLayout>
        <TripFooter direction="row" valign="middle" halign="center">
          <TripFooterLinkContainer>
            <Link href="/help-center">{'Pusat Bantuan'}</Link>
          </TripFooterLinkContainer>
          <TripFooterLinkContainer>
            <Link href="#">Syarat &amp; Ketentuan</Link>
          </TripFooterLinkContainer>
          <TripFooterLinkContainer>
            <Link href="#">Kebijakan Privasi</Link>
          </TripFooterLinkContainer>
        </TripFooter>
      </Fragment>
    );
  }
}

export default ProviderHomePage;
