import TripBox from '../TripBox/TripBox';
import { css, cx } from 'react-emotion';
import { StatelessComponent } from 'react';

const TripCardClass = css`
  padding: 16px;
  width: 100%;
  max-width: 300px;
  border-radius: 4px;
  text-align: center;
`;

export interface TripCardProps {
  className?: string;
}

const TripCard: StatelessComponent<TripCardProps> = (props) => {
  const { children, className, ...rest } = props;
  const classes = cx(className, TripCardClass);
  return (
    <TripBox elevation={1} className={classes} {...rest}>
      {children}
    </TripBox>
  );
};

TripCard.displayName = 'TripCard';

export default TripCard;
