import Head from 'next/head';
import Layout from '@shared/containers/Layout';
import ProviderHomePage from '../src/home/ProviderHomePage';

const IndexPage = () => (
  <Layout>
    <Head>
      <title>TRIP Extranet</title>
    </Head>

    <ProviderHomePage />
  </Layout>
);

export default IndexPage;
