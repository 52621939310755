import styled from 'react-emotion';
import { StatelessComponent, ReactNode } from 'react';

export interface TruncatorProps {
  children?: ReactNode;
  fontWeight?: number;
  fontSize?: number;
  lineHeight?: number;
  lineToShow?: number;
}

const TruncatorContainer = styled('span')<TruncatorProps>`
  margin: 0px;
  display: -webkit-box;
  height: ${({ fontSize = 16, lineHeight = 1.25, lineToShow = 1 }) =>
    fontSize * lineHeight * lineToShow}px;
  font-size: ${({ fontSize = 16 }) => fontSize}px;
  line-height: ${({ lineHeight = 1.25 }) => lineHeight};
  -webkit-line-clamp: ${({ lineToShow = 1 }) => lineToShow};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  white-space: normal;
  font-weight: ${(props) => props.fontWeight};
`;

const Truncator: StatelessComponent<TruncatorProps> = (props) => {
  const { children, ...rest } = props;
  return <TruncatorContainer {...rest}>{children}</TruncatorContainer>;
};

Truncator.displayName = 'Truncator';

export default Truncator;
